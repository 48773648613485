<template>
  <transition name="fade">
    <div
      v-if="active"
      class="fullscreen-layout fixed w-full h-full top-0 right-0 left-0 bottom-0 flex flex-column justify-center items-center"
    >
      <slot/>
    </div>
  </transition>
</template>
<script>
export default {
  name   : "fullscreen-layout",
  props  : {
    active: {
      type    : Boolean,
      required: true,
    },
  },
  watch  : {
    "active"(val) {
      this.toggleBodyScroll(val);
    },
  },
  methods: {
    toggleBodyScroll(val) {
      val ? document.getElementsByTagName("body")[ 0 ].style.position = "fixed" : document.getElementsByTagName("body")[ 0 ].style.position = "";
    },
  },
  mounted() {
    this.toggleBodyScroll(this.active);
  },
};
</script>
<style scoped lang="scss">
  .fullscreen-layout {
    background-color: red;
    z-index: 1000000000;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.5);
  }
</style>
