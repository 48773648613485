<template>
  <fullscreen-layout
    :active="isMobile() && $screen.width < 768 && $screen.portrait"
  >
    <div class="">
      <img
        :src="require('@/assets/images/orientation/to-horisontal.gif')"
        height="200"
        alt=""
      >
    </div>
  </fullscreen-layout>
</template>
<script>
import FullscreenLayout from "./FullscreenLayout";

export default {
  name: "MobileLandscapeTriggerLayout",
  components: { FullscreenLayout },
  methods: {
    isMobile() {
      return window.mobilecheck();
    },
  }
};
</script>
