var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"events-statistics"},[_c('page-title'),_c('mobile-landscape-trigger-layout'),_c('select-input',{staticClass:"m-0",attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': this,
        'key': 'eventsSelected',
        'multiple': true,
        'options': _vm.options,
        'clearable': false
      }
    }},on:{"input":_vm.onChange}}),_c('div',{staticClass:"date-pickers w-full flex flex-col lg:flex-row md:justify-end md:gap-2"},[_c('date-picker-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': this,
          'key': 'from',
          'clearable': false
        },
      }}}),_c('date-picker-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': this,
          'key': 'to',
          'clearable': false

        },
      }}}),_c('div',{staticClass:"flex justify-end xs:items-end xs:mb-4"},[_c('a-button',{attrs:{"type":"primary","icon":"search","disabled":!_vm.eventsSelected.length || !_vm.from || !_vm.to},on:{"click":_vm.getStatistics}})],1)],1),(_vm.charts && _vm.charts.length)?_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('events_count_stat_title')}},[_c('vue-apex-charts',{attrs:{"type":"area","height":"400px","options":_vm.chartOptions,"series":_vm.charts}})],1):_c('empty-data',{staticClass:"mt-5",attrs:{"description":_vm.$t('events_selected_empty')}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }