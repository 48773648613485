










































































import EventService, { EventStatisticRecord } from '@/includes/services/EventService'
import EventStatisticsMixin from '@/mixins/statistics/Events/EventStatisticsMixin'
import { InputSetups } from '@/mixins/input-setups'
import { fromToChartTicks } from '@/mixins/statistics/FromToChartTicks'
import MobileLandscapeTriggerLayout from '@/components/MobileLandscapeTriggerLayout.vue'
import { UserConfig } from '@/includes/services/UserService'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import { ApexChartLocales } from 'piramis-base-components/src/shared/types/ApexChartSeries.types'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import moment from 'moment'
import { cloneDeep, isEmpty } from 'lodash'
import { Component, Mixins } from 'vue-property-decorator'
import VueApexCharts from 'vue-apexcharts'
import { ApexOptions } from 'apexcharts'

@Component({
  components: {
    VueApexCharts,
    PageTitle,
    MobileLandscapeTriggerLayout,
    EmptyData
  }
})
export default class EventsStatistics extends Mixins<EventStatisticsMixin, UseFields, InputSetups>(EventStatisticsMixin, UseFields, InputSetups) {
  events: Record<string, number> = {}

  eventsSelected = []

  activeKeys: Array<number> = []

  from = moment().add(-1, 'month').format('YYYY-MM-DD')

  to = moment().format('YYYY-MM-DD')
  
  charts: any = {}

  key = 0

  chartOptions: ApexOptions = {
    chart: {
      type: 'area',
      defaultLocale: this.$i18n.locale,
      locales: ApexChartLocales,
      animations: {
        enabled: false,
      },
    },
    stroke: {
      curve: 'smooth'
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      labels: {
        datetimeUTC: false
      },
      type: "datetime",
      categories: fromToChartTicks(this.from, this.to).timeTicks,
    },
  }

  get options() {
    const user = this.$store.state.userState.currentUserConfig

    if (user) {
      if (Object.keys(user.config.events).length)
        return Object.entries(user.config.events)
          .map(([ eventName, eventKey ]) => ({
            label: eventName,
            value: eventKey
          }))
    }

    return []
  }

  onChange(value:Array<SelectOption>) {
    this.activeKeys = value.map(v => v.value)
  }

  updateCharts(eventsStat: Array<EventStatisticRecord>, events: UserConfig['events']):void {
    const series:Array<any> = []

    if (this.eventsSelected.length) {
      this.eventsSelected.forEach(key => {
        Object.entries(events).forEach(([ eventName, eventKey ]) => {
          if (eventKey === key) {
            series.push(this.prepareEventWeightStat(eventsStat, eventName, eventKey as number, this.from, this.to))
          }
        })
      })

      this.charts = series

      this.key += 1
    }
  }

  getStatistics(): void {
    EventService.getEventsStat('tg', { from: this.from, to: this.to })
      .then(({ items }) => {
        const user = items.find(u => u.key.split('.')[0] === this.$route.params.id)
        const events = this.$store.state.userState.currentUserConfig?.config.events

        if (user && !isEmpty(events)) {
          this.updateCharts(user?.stat ?? [], events)
        }
      })
  }

  created(): void {
    if (!isEmpty(this.$store.state.userState.currentUserConfig.config.events)) {
      this.events = cloneDeep(this.$store.state.userState.currentUserConfig.config.events)
      this.getStatistics()
    }
  }
}
